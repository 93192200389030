
const ItemData =[
    {
        "id":1,
        "image":"image/img.png",
    },
    {
        "id":2,
        "image":"image/img2.png",
        
    },
    {
        "id":3,
        "image":"image/img3.png",
       
    },
    {
        "id":4,
        "image":"image/img4.png",
        
    },
    {
        "id":5,
        "image":"image/img5.png",
       
    },
    {
        "id":6,
        "image":"image/img6.png",
       
    },
    {
        "id":7,
        "image":"image/img7.png",
       
    },
    {
        "id":8,
        "image":"image/img8.png",
       
    },
    {
        "id":9,
        "image":"image/img9.png",
       
    },
    {
        "id":10,
        "image":"image/img10.png",
       
    },
    {
        "id":11,
        "image":"image/img11.png",
       
    },
    {
        "id":11,
        "image":"image/img11.png",
       
    }
    
]

export default ItemData;